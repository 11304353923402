import React, {useState} from 'react';
import styled from "styled-components";
import Header from "../components/header";
import Footer from "../components/footer";
import Decoration from "../images/decoration.svg";
import {Popover} from "@material-ui/core";
import {graphql, useStaticQuery} from "gatsby";
import "../index.css"
import Seo from "../components/seo";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 199px;
  flex: 1 1 auto;
  @media screen and (max-width: 1100px) {
    margin-bottom: 170px;
  }
  @media screen and (max-width: 960px) {
    margin-bottom: 150px;
  }
  @media screen and (max-width: 800px) {
    margin-bottom: 130px;
  }
`;

const Container = styled.div`
  width: 1110px;
  align-self: center;
  @media screen and (max-width: 1100px) {
    width: 1000px;
  }
  @media screen and (max-width: 960px) {
    width: 850px;
  }
  @media screen and (max-width: 800px) {
    width: 700px;
  }
`;

const Title = styled.div`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 30px;
  color: #4C77FF;
  margin-bottom: 60px;
  align-self: center;
  width: min-content;
  @media screen and (max-width: 1100px) {
    margin-bottom: 40px;
    font-size: 25px;
  }
  @media screen and (max-width: 960px) {
    margin-bottom: 30px;
    font-size: 23px;
  }
  @media screen and (max-width: 800px) {
    margin-bottom: 25px;
    font-size: 20px;
  }
`;

const Text = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  line-height: 20px;
  color: #212939;
  white-space: break-spaces;
  text-align: center;
  width: 255px;
  @media screen and (max-width: 1100px) {
    font-size: 13px;
    line-height: 18px;
    width: 225px;
  }
  @media screen and (max-width: 960px) {
    font-size: 11px;
    line-height: 15px;
    width: 190px;
  }
  @media screen and (max-width: 800px) {
    font-size: 9px;
    line-height: 12px;
    width: 160px;
  }
`;

const ContainerForImage = styled.div`
  background: #E1F0FF;
  width: 255px;
  height: 209px;
  margin-right: ${props => props.index % 4 !== 0 || props.index === 1 || props.index === 2 || props.index === 3 ? "30px" : "0"};
  margin-bottom: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1100px) {
    height: 190px;
    width: 225px;
  }
  @media screen and (max-width: 960px) {
    width: 190px;
    height: 170px;
  }
  @media screen and (max-width: 800px) {
    height: 150px;
    margin-right: ${props => props.index % 4 !== 0 || props.index === 1 || props.index === 2 || props.index === 3 ? "20px" : "0"};
    width: 160px;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 30px;
`;

const Logo = styled.img`
  @media screen and (max-width: 1100px) {
    width: 180px;
  }
  @media screen and (max-width: 960px) {
    width: 160px;
  }
  @media screen and (max-width: 800px) {
    width: 140px;
  }
`;

const FrameText = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  line-height: 155%;
  color: #000000;
  padding: 25px 16px;
  box-sizing: border-box;
  background: #FFFFFF;
  white-space: break-spaces;
  @media screen and (max-width: 1100px) {
    font-size: 12px;
    padding: 20px 12px;
  }
  @media screen and (max-width: 960px) {
    font-size: 10px;
    padding: 18px 10px;
  }
  @media screen and (max-width: 800px) {
    font-size: 8px;
    line-height: 155%;
    padding: 15px 8px;
  }
`;


const ReturnImages = () => {
    return (
        <>
            <Image src={Decoration} alt="decoration"/>
            <Image2 src={Decoration} alt="decoration"/>
        </>
    )
}

const Image = styled.img`
  position: absolute;
  left: -460px;
  top: 10px;
`;

const Image2 = styled.img`
  position: absolute;
  left: 1230px;
  top: 680px;
  z-index: -1;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  @media screen and (max-width: 800px) {
    display: none;
  }
`;


const Partners = () => {

    const [anchorEl1, setAnchorEl1] = useState(null);

    const handlePopoverOpen1 = (event) => {
        setAnchorEl1(event.currentTarget);
    };

    const handlePopoverClose1 = () => {
        setAnchorEl1(null);
    };

    const [anchorEl2, setAnchorEl2] = useState(null);

    const handlePopoverOpen2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handlePopoverClose2 = () => {
        setAnchorEl2(null);
    };

    const [anchorEl3, setAnchorEl3] = useState(null);

    const handlePopoverOpen3 = (event) => {
        setAnchorEl3(event.currentTarget);
    };

    const handlePopoverClose3 = () => {
        setAnchorEl3(null);
    };

    const [anchorEl4, setAnchorEl4] = useState(null);

    const handlePopoverOpen4 = (event) => {
        setAnchorEl4(event.currentTarget);
    };

    const handlePopoverClose4 = () => {
        setAnchorEl4(null);
    };

    const [anchorEl5, setAnchorEl5] = useState(null);

    const handlePopoverOpen5 = (event) => {
        setAnchorEl5(event.currentTarget);
    };

    const handlePopoverClose5 = () => {
        setAnchorEl5(null);
    };

    const [anchorEl6, setAnchorEl6] = useState(null);

    const handlePopoverOpen6 = (event) => {
        setAnchorEl6(event.currentTarget);
    };

    const handlePopoverClose6 = () => {
        setAnchorEl6(null);
    };

    const [anchorEl7, setAnchorEl7] = useState(null);

    const handlePopoverOpen7 = (event) => {
        setAnchorEl7(event.currentTarget);
    };

    const handlePopoverClose7 = () => {
        setAnchorEl7(null);
    };

    const [anchorEl8, setAnchorEl8] = useState(null);

    const handlePopoverOpen8 = (event) => {
        setAnchorEl8(event.currentTarget);
    };

    const handlePopoverClose8 = () => {
        setAnchorEl8(null);
    };

    const [anchorEl9, setAnchorEl9] = useState(null);

    const handlePopoverOpen9 = (event) => {
        setAnchorEl9(event.currentTarget);
    };

    const handlePopoverClose9 = () => {
        setAnchorEl9(null);
    };

    const [anchorEl10, setAnchorEl10] = useState(null);

    const handlePopoverOpen10 = (event) => {
        setAnchorEl10(event.currentTarget);
    };

    const handlePopoverClose10 = () => {
        setAnchorEl10(null);
    };

    const [anchorEl11, setAnchorEl11] = useState(null);

    const handlePopoverOpen11 = (event) => {
        setAnchorEl11(event.currentTarget);
    };

    const handlePopoverClose11 = () => {
        setAnchorEl11(null);
    };


    const [anchorEl12, setAnchorEl12] = useState(null);

    const handlePopoverOpen12 = (event) => {
        setAnchorEl12(event.currentTarget);
    };

    const handlePopoverClose12 = () => {
        setAnchorEl12(null);
    };

    const [anchorEl13, setAnchorEl13] = useState(null);

    const handlePopoverOpen13 = (event) => {
        setAnchorEl13(event.currentTarget);
    };

    const handlePopoverClose13 = () => {
        setAnchorEl13(null);
    };

    const [anchorEl14, setAnchorEl14] = useState(null);

    const handlePopoverOpen14 = (event) => {
        setAnchorEl14(event.currentTarget);
    };

    const handlePopoverClose14 = () => {
        setAnchorEl14(null);
    };

    const [anchorEl15, setAnchorEl15] = useState(null);

    const handlePopoverOpen15 = (event) => {
        setAnchorEl15(event.currentTarget);
    };

    const handlePopoverClose15 = () => {
        setAnchorEl15(null);
    };

    const [anchorEl16, setAnchorEl16] = useState(null);

    const handlePopoverOpen16 = (event) => {
        setAnchorEl16(event.currentTarget);
    };

    const handlePopoverClose16 = () => {
        setAnchorEl16(null);
    };

    const arrOfOpenFunctions = [handlePopoverOpen1, handlePopoverOpen2, handlePopoverOpen3, handlePopoverOpen4, handlePopoverOpen5, handlePopoverOpen6,
        handlePopoverOpen7, handlePopoverOpen8, handlePopoverOpen9, handlePopoverOpen10, handlePopoverOpen11, handlePopoverOpen12, handlePopoverOpen13,
        handlePopoverOpen14, handlePopoverOpen15, handlePopoverOpen16]

    const arrOfCloseFunctions = [handlePopoverClose1, handlePopoverClose2, handlePopoverClose3, handlePopoverClose4, handlePopoverClose5, handlePopoverClose6,
        handlePopoverClose7, handlePopoverClose8, handlePopoverClose9, handlePopoverClose10, handlePopoverClose11, handlePopoverClose12, handlePopoverClose13,
        handlePopoverClose14, handlePopoverClose15, handlePopoverClose16]


    const data = useStaticQuery(query);

    const arrOfTexts = []
    for (let i = 0; i < data.allStrapiPartners.edges.length; i++) {
        arrOfTexts[i] = data.allStrapiPartners.edges[i].node.textFrame
    }

    return (
            <div style={{minHeight: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                <Seo title={data.allStrapiPartnersSeos.nodes[0].seo_title}
                     description={data.allStrapiPartnersSeos.nodes[0].seo_description}
                     keywords={data.allStrapiPartnersSeos.nodes[0].seo_keywords.split(',')}
                />
                <MainContainer>
                    <div style={{alignSelf: "center"}}>
                        <Header type="partners"/>
                    </div>
                    <Container>
                        <Title>
                            Партнеры
                        </Title>
                        <div style={{display: "flex", flexWrap: "wrap", position: "relative"}}>
                            <ReturnImages/>
                            {data.allStrapiPartners.edges.map((item, index) => (
                                <>
                                    <ColumnContainer onMouseEnter={arrOfOpenFunctions[index]}
                                                     onMouseLeave={arrOfCloseFunctions[index]}>
                                        <ContainerForImage index={index + 1}>
                                            <Logo src={item.node.image.localFile.url} alt="logo"/>
                                        </ContainerForImage>
                                        <Text>
                                            {item.node.title}
                                        </Text>
                                    </ColumnContainer>
                                </>
                            ))}
                            <Popover
                                open={Boolean(anchorEl1)}
                                style={{
                                    pointerEvents: 'none',
                                }}
                                anchorEl={anchorEl1}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: "center",
                                    horizontal: "left"
                                }}
                            >
                                <FrameText>
                                    {arrOfTexts[0]}
                                </FrameText>
                            </Popover>
                            <Popover
                                open={Boolean(anchorEl2)}
                                style={{
                                    pointerEvents: 'none',
                                }}
                                anchorEl={anchorEl2}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left"
                                }}
                            >
                                <FrameText>
                                    {arrOfTexts[1]}
                                </FrameText>
                            </Popover>
                            <Popover
                                open={Boolean(anchorEl3)}
                                style={{
                                    pointerEvents: 'none',
                                }}
                                anchorEl={anchorEl3}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: "center",
                                    horizontal: "left"
                                }}
                            >
                                <FrameText>
                                    {arrOfTexts[2]}
                                </FrameText>
                            </Popover>
                            <Popover
                                open={Boolean(anchorEl4)}
                                style={{
                                    pointerEvents: 'none',
                                }}
                                anchorEl={anchorEl4}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: "center",
                                    horizontal: "right"
                                }}
                            >
                                <FrameText>
                                    {arrOfTexts[3]}
                                </FrameText>
                            </Popover>
                            <Popover
                                open={Boolean(anchorEl5)}
                                style={{
                                    pointerEvents: 'none',
                                }}
                                anchorEl={anchorEl5}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: "center",
                                    horizontal: "left"
                                }}
                            >
                                <FrameText>
                                    {arrOfTexts[4]}
                                </FrameText>
                            </Popover>
                            <Popover
                                open={Boolean(anchorEl6)}
                                style={{
                                    pointerEvents: 'none',
                                }}
                                anchorEl={anchorEl6}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: "center",
                                    horizontal: "left"
                                }}
                            >
                                <FrameText>
                                    {arrOfTexts[5]}
                                </FrameText>
                            </Popover>
                            <Popover
                                open={Boolean(anchorEl7)}
                                style={{
                                    pointerEvents: 'none',
                                }}
                                anchorEl={anchorEl7}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: "center",
                                    horizontal: "left"
                                }}
                            >
                                <FrameText>
                                    {arrOfTexts[6]}
                                </FrameText>
                            </Popover>
                            <Popover
                                open={Boolean(anchorEl8)}
                                style={{
                                    pointerEvents: 'none',
                                }}
                                anchorEl={anchorEl8}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: "center",
                                    horizontal: "right"
                                }}
                            >
                                <FrameText>
                                    {arrOfTexts[7]}
                                </FrameText>
                            </Popover>
                            <Popover
                                open={Boolean(anchorEl9)}
                                style={{
                                    pointerEvents: 'none',
                                }}
                                anchorEl={anchorEl9}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: "center",
                                    horizontal: "left"
                                }}
                            >
                                <FrameText>
                                    {arrOfTexts[8]}
                                </FrameText>
                            </Popover>
                            <Popover
                                open={Boolean(anchorEl10)}
                                style={{
                                    pointerEvents: 'none',
                                }}
                                anchorEl={anchorEl10}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: "center",
                                    horizontal: "left"
                                }}
                            >
                                <FrameText>
                                    {arrOfTexts[9]}
                                </FrameText>
                            </Popover>
                            <Popover
                                open={Boolean(anchorEl11)}
                                style={{
                                    pointerEvents: 'none',
                                }}
                                anchorEl={anchorEl11}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: "center",
                                    horizontal: "left"
                                }}
                            >
                                <FrameText>
                                    {arrOfTexts[10]}
                                </FrameText>
                            </Popover>
                            <Popover
                                open={Boolean(anchorEl12)}
                                style={{
                                    pointerEvents: 'none',
                                }}
                                anchorEl={anchorEl12}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: "center",
                                    horizontal: "right"
                                }}
                            >
                                <FrameText>
                                    {arrOfTexts[11]}
                                </FrameText>
                            </Popover>
                            <Popover
                                open={Boolean(anchorEl13)}
                                style={{
                                    pointerEvents: 'none',
                                }}
                                anchorEl={anchorEl13}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: "center",
                                    horizontal: "left"
                                }}
                            >
                                <FrameText>
                                    {arrOfTexts[12]}
                                </FrameText>
                            </Popover>
                            <Popover
                                open={Boolean(anchorEl14)}
                                style={{
                                    pointerEvents: 'none',
                                }}
                                anchorEl={anchorEl14}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: "center",
                                    horizontal: "left"
                                }}
                            >
                                <FrameText>
                                    {arrOfTexts[13]}
                                </FrameText>
                            </Popover>
                            <Popover
                                open={Boolean(anchorEl15)}
                                style={{
                                    pointerEvents: 'none',
                                }}
                                anchorEl={anchorEl15}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: "center",
                                    horizontal: "left"
                                }}
                            >
                                <FrameText>
                                    {arrOfTexts[14]}
                                </FrameText>
                            </Popover>
                            <Popover
                                open={Boolean(anchorEl16)}
                                style={{
                                    pointerEvents: 'none',
                                }}
                                anchorEl={anchorEl16}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: "center",
                                    horizontal: "right"
                                }}
                            >
                                <FrameText>
                                    {arrOfTexts[15]}
                                </FrameText>
                            </Popover>
                        </div>
                    </Container>
                </MainContainer>
                <Footer type="partners"/>
            </div>
    )
}

const query = graphql`
query{
  allStrapiPartners {
    edges {
      node {
        textFrame
        title
        image {
          localFile {
            url
          }
        }
      }
    }
  }
    allStrapiPartnersSeos {
    nodes {
      seo_title
      seo_decription
      seo_keywords
    }
  }
}
`;

export default Partners;